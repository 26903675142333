#root{
    overflow-y:hidden;
}

#TermsAndConditions{
    .terms-and-conditions-footer{
        width:100%;
        display: flex;
        justify-content:flex-end;
    }
    .skip-step:not([disabled]){
        color: rgb(0, 11, 87);
    }
    .skip-step{
        font-weight: 600;
        padding-bottom: 2rem;
        padding-right: 1rem;
        display: flex;
        width: fit-content;
        background-color: transparent;
        border: none;
        font-size: 1.25rem;
    }
    .skip-step:hover:not([disabled]){
        cursor: pointer;
        text-decoration: underline;
    }
    display:flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: auto;
    background-color:  #f2f2f2;

    .terms-and-conditions-page-wrapper {
        display:flex;
        flex-direction: column;
        flex-grow: 1;
        overflow: auto;
    }

    .page-container{
        display:flex;
        flex-direction: column;
        gap:1.5rem; 
        flex-grow:1;
        padding: 0 1.5rem 1.5rem 1.5rem;
        overflow: auto;
    }

    .header-container{
        display: flex;
        justify-content: center;
        justify-items: center;
    }

    .header{
        display: flex;
        padding-top:1rem;
        justify-content: center;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    .title {
        display: flex;
        flex-direction: column;
        width:100%;
        font-weight: 600;            
        font-size: 1.3rem;
    }

    .document{
        cursor: pointer;
        text-decoration:underline;
        font-weight: 600;
        color:#040b50;
    }

    .fix-later{
        color:darkgray;
    }

    ul{
        padding-bottom:1rem;
    }

    .checkbox-header-container{
        display:flex;
        gap:2rem;
        align-items: center;

        label{
            font-size: 20px;
            font-weight: 600;
        }
    }

    .submitButton{
        color:white;
        font-size:20px;
        font-weight:600;
        border-radius: 5px;
        border:unset;
        padding:.25rem 3rem .5rem .5rem;
        font-family: "Barlow Medium", "Barlow", sans-serif;
        background-color: #040b50;
        text-align: start;
        width:10rem;
        height:5rem;
        cursor: pointer;
    }

    .submitButton:disabled{
        color:#cfcfcf;
        background-color: #9c9eb2;
        cursor: unset;
    }

    .checkbox-container{
        display: flex;
        gap: 10px;
        align-items: center;
    }

    .terms-and-conditions-container{
        display: flex;
        border:1px solid #040b50;
        background-color: #f2f2f2;
        border-radius:10px;        
        overflow: auto;
    }
    
    .terms-and-conditions-scroll-container{
        display: flex;
        flex-grow: 1;
        overflow: auto;
        background-color: white;
        padding-right:1rem;
    }

    .terms-and-conditions-list{
        display: flex;
        flex-direction: column;
        gap:1rem;

        li{
            width: fit-content;
            font-size:18px;
        }
    }

    .terms-and-conditions-sublist{
        display: flex;
        flex-direction: column;
        gap:1rem;

        li{
            width: fit-content;
            font-size:18px;
        }
    }


    @media (min-width: 801px) {

        background-color: white;

        .terms-and-conditions-page-wrapper {
            padding-left: 10.5rem;
            padding-right: 10.5rem;
            scrollbar-gutter: stable;
        }

        .page-container{
            padding-left: unset;
            padding-right: unset;
        }

        .header {
            padding-left: unset;
            padding-right: unset;
        }

        .submitButton{
            color:white;
            font-size:20px;
            font-weight:550;
            padding: .5rem;
            border-radius: 5px;
            border:unset;
            font-family: "Barlow SemiBold", "Barlow", sans-serif;
            background-color: #040b50;
            height:unset;
            width:unset;
        }

        .title {            
            font-weight: bold;            
            font-size: 28px;
        }
        .terms-and-conditions-scroll-container{
            background-color: #f2f2f2;
        }


    }
}